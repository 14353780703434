import React, { useState } from "react"
import '../App.css';

import Header from "./header";
import Description from "./description"
import Jobs from "./jobs"
import Technologies from "./technologies";
import Education from "../components/education"
import Languages from "../components/languages"
import Licence from "../components/licence"
import Courses from "../components/courses"

import Data from "../assets/Ivars_Saudinis_21.08.2023. 15_11_17.json"

export default function Window() {

    const [data, setData] = useState(Data)

    return (
        <div className="window">
            <Header data={data.basics} />
            <Description data={data.basics.summary} />
            <Technologies data={data.skills}/>
            <Jobs data={data.work} />
            <Education data={data.education}/>
            <Languages data={data.languages}/>
            <Licence />
            <Courses data={data.courses}/>
        </div>
    )
}