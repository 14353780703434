import React from "react"

export default function Languages(data) {

    return (
        <div className="languages">
            <h2>Valodas</h2>
            <table>
                <thead>
                    <tr>
                        {data.data[0].headers.map(x => <th key={x}>{x}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {data.data[0].languages.map(x => <tr key={x.name}><td>{x.name}</td><td>{x.klausīšanās}</td><td>{x.lasīšana}</td><td>{x.komunikācija}</td><td>{x.prezentācija}</td><td>{x.rakstīšana}</td></tr>)}
                </tbody>
            </table>
            <p className="grey-text"> Līmeņi: A1/A2 - pamatlīmenis; B1/B2 - vidējais līmenis; C1/C2 - augstākais līmenis</p>
        </div>
    )
}