import React from "react"

export default function Lixcence() {
    return (
        <div className="licence">
            <h2>Vadītāja apliecība</h2>
            <p className="grey-text">Apliecība iegūta: <strong>2013</strong></p>
            <p className="grey-text">Kategorija: <strong> B</strong></p>

        </div>
    )
}