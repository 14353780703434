import React from "react"

export default function Courses(data) {

    const date = (data) => {
        let newdata = new Date(data)
        const monthNames = ["Janvāris", "Februāris", "Marts", "Aprīlis", "Maijs", "Jūnijs",
            "Jūlijs", "Augusts", "Septembris", "Oktobris", "Novembris", "Decembris"
        ];

        let dat = newdata.getFullYear() + " " + monthNames[newdata.getMonth()]

        return dat
    }

    return (

        <div className="courses">
            <h2>Cits</h2>
            {data.data.map(x =>
                <div key={x.name}>
                    <h3>{x.name}</h3>
                    <p className="grey-text">{x.company} / {date(x.date)}</p>
                </div>
            )}
        </div>
    )
}