import React from "react"
import '../App.css';

export default function(data){

    return(
        <div className="description">
        <h2>Kopsavilkums</h2>
        <p>{data.data}</p>
    </div>
    )
}