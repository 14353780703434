import React, { useState } from "react"
import '../App.css';

export default function Jobs(data) {

    const [work, setWork] = useState(data)
    
    const datums = (start, end) => {

        let newstart = new Date(start)
        let newend = new Date(end)

        let date = ""
        date = newstart.getFullYear() + ". g." + "-" + newend.getFullYear() + ". g."
        return date
    }
    return (

        <div className="jobs">
            <h2>Darba pieredze</h2>
            {work.data.map(x =>
            <div key={x.id}>
                    <h3>{x.position}</h3>
                    <p className="grey-text">{x.name}</p>
                    <p className="grey-text">{datums(x.startDate, x.endDate)}</p>

                    <div dangerouslySetInnerHTML={{__html: x.summary }} />
                    </div>
            )}
        </div>
    )
}