import React, { useEffect, useState } from "react"
import '../App.css';

export default function Technologies(data) {
    const [skills, setSkills] = useState([])

    useEffect(() => {
        setSkills(skills.concat(data.data.languages, data.data.frameworks,   data.data.databases, data.data.libraries, data.data.tools))
    }, [data])

    return (
        <div className="technologies">
            <h2>Tehnoloģijas</h2>
            <ul>
                {skills.map(x => <li key={x.name}>{x.name}</li>)}
            </ul>
        </div>
    )
}