import React from "react"

export default function Education(data) {

    const datums = (start, end) => {

        let newstart = new Date(start)
        let newend = new Date(end)

        let date = ""
        date = newstart.getFullYear() + ". g." + "- " + newend.getFullYear() + ". g."
        return date
    }
    return (
        <div className="education">
            <h2>Izglītība</h2>
            {data.data.map(x =>
                <div key={x.id}>
                    <h3>{x.institution}</h3>
                    <p className="grey-text">{x.area} / {x.studyType}</p>
                    <p className="grey-text">{datums(x.startDate, x.endDate)}</p>
                </div>
            )}
        </div>
    )
}