import React from "react"
//import Avatar from "../assets/src/assets/pic.jpeg"
import '../App.css'

export default function Header (data){

    return(
        <div className="header">
        <img src={data.data.image} />
        <div>
            <h1>{data.data.name}</h1>
            <h3 className="job">{data.data.label}</h3>
            <div className="header-data">
                <span><i className="fas fa-phone"></i>{data.data.phone}</span>
                <span><i className="fas fa-envelope"></i>{data.data.email}</span>
                <span><i className="fas fa-map-marker"></i>{data.data.location.city}</span>
                <span> <a href={data.data.url}><i className="fas fa-globe"></i>{data.data.url}</a></span>

            </div>
        </div>
    </div>
    )
}