import logo from './logo.svg';
import './App.css';
import Window from "./components/window"

function App() {
  return (
    <Window />

  )
}

export default App;
